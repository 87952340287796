/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DmImg from "assets/images/seoinfo.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import { styled } from "@mui/material/styles";

const Imgweb = styled("img")`
  width: 100%;
`;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function Infoseo() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              Expert SEO services to get your website
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE!important" textAlign="center">
              ranked across top search engines.
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Bgicons src={Bgicon} />
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6, pr: 6 }}>
            <Imgweb src={DmImg} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              At Universal Softech, we offer high-quality SEO services to help businesses improve
              their online presence and attract more traffic to their website. Our team of experts
              focuses on optimizing website content, structure, and backlinks to improve search
              engine rankings and generate more leads and conversions. We also provide local SEO
              services to help businesses attract more local customers and improve their local
              search rankings. With our effective SEO strategies, we help businesses stay ahead of
              the competition and achieve greater success in the digital landscape. Thank you for
              considering Universal Softech for your SEO needs.
            </MKTypography>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
    </MKBox>
  );
}

export default Infoseo;
