import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import webdesigin from "assets/images/websitedesigin.png";
import webapplication from "assets/images/webapplication.png";
import webecommerce from "assets/images/ecommerceweb.png";
import webplugin from "assets/images/plugindev.png";

import { styled } from "@mui/material/styles";

const WebdesignIcon = styled("img")`
  margin: auto;
  display: block;
`;
const ColumeService = styled("div")`
  background-color: #ffffff;
  padding: 40px 20px 0px 20px;
  box-shadow: 0px 4px 8px 3px #e9edff;
  border-radius: 20px;
  height: 300px;
`;

function WebOurservices() {
  return (
    <MKBox
      component="section"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      bgcolor="#f0f0f0 !important"
    >
      <Container>
        <MKTypography variant="h3" color="#5d51dc" textAlign="center" opacity={0.8} mt={1} mb={3}>
          <span style={{ color: "#5f53de" }}>Web</span> Our Services
          <div className="em_bar">
            <div className="em_bar_bg"></div>
          </div>
        </MKTypography>
        <Grid container md={12}>
          <Grid item xs={12} md={6} p={2}>
            <ColumeService>
              <WebdesignIcon src={webdesigin} />
              <MKTypography variant="h6" color="#5d51dc" textAlign="center">
                Web Our Services
              </MKTypography>
              <MKTypography variant="body1" color="#5d51dc" textAlign="center">
                Design software creates visuals, not functioning websites. Converting designs to
                HTML, CSS, and JavaScript requires coding and design skills. Tools like Bootstrap
                can help streamline the process.
              </MKTypography>
            </ColumeService>
          </Grid>
          <Grid item xs={12} md={6} p={2}>
            <ColumeService>
              <WebdesignIcon src={webapplication} />
              <MKTypography variant="h6" color="#5d51dc" textAlign="center">
                Web Application Development
              </MKTypography>
              <MKTypography variant="body1" color="#5d51dc" textAlign="center">
                Universal softech offers custom web application development services tailored to
                meet your business needs. Our team of skilled developers uses the latest
                technologies and frameworks to deliver high-quality web applications that meet your
                requirements and exceed your expectations.
              </MKTypography>
            </ColumeService>
          </Grid>
          <Grid item xs={12} md={6} p={2}>
            <ColumeService>
              <WebdesignIcon src={webecommerce} />
              <MKTypography variant="h6" color="#5d51dc" textAlign="center">
                Website Development For E-Commerce
              </MKTypography>
              <MKTypography variant="body1" color="#5d51dc" textAlign="center">
                Universal softech provides website development for e-commerce businesses, using the
                latest technologies and frameworks to create user-friendly and secure online
                shopping experiences. We tailor our services to meet your business needs and help
                you achieve your sales goals.
              </MKTypography>
            </ColumeService>
          </Grid>
          <Grid item xs={12} md={6} p={2}>
            <ColumeService>
              <WebdesignIcon src={webplugin} />
              <MKTypography variant="h6" color="#5d51dc" textAlign="center">
                Development of Plug-ins
              </MKTypography>
              <MKTypography variant="body1" color="#5d51dc" textAlign="center">
                Universal softech offers expert development of plug-ins that can extend the
                functionality of your website or application. Our team of developers creates custom
                plug-ins that meet your unique requirements and provide enhanced features and
                capabilities.
              </MKTypography>
            </ColumeService>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default WebOurservices;
