import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Author page sections

// import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";
import { styled } from "@mui/material/styles";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import Hireimg from "assets/images/hire.png";
//import LinKIcon from "assets/images/arrow-inner-link.png";
//import { Link } from "react-router-dom";
// import { Route } from "react-router-dom";
import InfoApp from "pages/LandingPages/OurServices/AppDevlopment/section/InfoApp";
import Technologyapp from "pages/LandingPages/OurServices/AppDevlopment/section/Technologyapp";
import Hiringresouress from "pages/Presentation/sections/Hiringresoures";
import Container from "@mui/material/Container";
const Hireheaderimg = styled("img")`
  width: 100%;
`;
// const SlideHeader = styled("div")`
//   margin: auto;
//   width: fit-content;
//   background: linear-gradient(86deg, #49a3f1, #5f52dd) !important;
//   border-radius: 10px;
//   color: #ffffff;
//   display: grid;
//   align-items: center;
//   grid-template-columns: auto auto auto;
//   padding: 10px;
//   margin-top: 20px;
//   column-gap: 20px;
// `;

function Hireresources() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react1",
          label: "Apply Now",
          color: "info",
        }}
        sticky
      />
      <MKBox bgcolor="#ffffff !important">
        <MKBox
          //minHeight="15rem"
          width="100%"
          sx={{
            // backgroundImage: `url(${bgImage})`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            justifyItems: "center",
            alignItems: "stretch",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <MKTypography variant="h1" textAlign="center" marginTop="13rem" marginBottom="2rem">
            Get Hired: Find Your Technical Dream Job Here
          </MKTypography>
        </MKBox>
        <Container>
          <Hireheaderimg src={Hireimg} />
        </Container>
        <InfoApp />
        <Technologyapp />
        <Hiringresouress />
      </MKBox>
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Hireresources;
