/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import AppdevImg from "assets/images/appseconeimg.png";
import Handshowmobile from "assets/images/appsecimg.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import iconList from "assets/images/list-icon.png";

// Material Kit 2 React examples
import { styled } from "@mui/material/styles";
const Imgapp = styled("img")`
  width: 80%;
  float: right;
  @media screen and (max-width: 600px) {
    float: none;
  }
`;
const Imgweb = styled("img")`
  width: 80%;
`;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function InfoApp() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              We Are Best Sevices In
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE" textAlign="center">
              Mobile App Development
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Bgicons src={Bgicon} />
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <Imgweb src={AppdevImg} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Mobile app development services are designed to help businesses and individuals create
              custom mobile applications that meet their specific needs. These services typically
              provide end-to-end solutions, from initial concept and design to development, testing,
              and deployment.
            </MKTypography>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
      <Container>
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="h6" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Here are some of the main services that mobile app development companies provide to
              their clients:
            </MKTypography>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Consulting: Mobile app development companies offer consulting services to help clients determine the best mobile app development strategy for their business. This involves analyzing their requirements, identifying the target audience, and recommending the most appropriate mobile platforms and technologies." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Design: Mobile app development companies provide design services to create a visually appealing and user-friendly interface for the app. This involves designing wireframes, prototypes, and user interfaces that meet the client's requirements and preferences." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Development: Mobile app development companies provide development services to code the app using the latest programming languages and development tools. This involves building the front-end and back-end of the app, integrating APIs, and ensuring that the app works seamlessly on different mobile platforms." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Testing: Mobile app development companies provide testing services to ensure that the app is free of bugs and glitches. This involves running various tests, such as unit tests, integration tests, and acceptance tests, to verify that the app meets the required standards." />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <Imgapp src={Handshowmobile} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InfoApp;
