import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Overview from "assets/images/Customer.png";
import Philosophy from "assets/images/adminpenal.png";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import MKTypography from "components/MKTypography";

const TabIcon = styled("img")`
  width: 40px;
`;
const InnerIcon = styled("span")`
  background-color: #fff;
  padding: 20px;
  display: block;
  margin: 10px;
`;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, border: "2px solid #dad7f8", borderRadius: 2 }}>{children}</Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminInfoTab = ({ customerData, adminData }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mb: 6 }}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ backgroudColor: "#cbc6ff", borderColor: "divider", width: "40%", mx: "auto" }}
          className="tab_header"
        >
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab icon={<img src={Overview} />} label="Customer" {...a11yProps(0)} />
            <Tab icon={<img src={Philosophy} />} label="Admin Panel" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid
            container
            px={4}
            py={4}
            textAlign="center"
            style={{
              background:
                "radial-gradient(closest-side, rgb(218 215 248 / 80%), rgb(218 215 248 / 60%), rgb(218 215 248 / 40%), rgb(218 215 248 / 0%)",
            }}
          >
            {customerData && customerData.length > 0 ? (
              customerData.map((item, index) => (
                <Grid key={index} xs={6} md={3}>
                  <InnerIcon>
                    <TabIcon src={item.iconSrc} />
                    <MKTypography variant="body1">{item.label}</MKTypography>
                  </InnerIcon>
                </Grid>
              ))
            ) : (
              <p>No data available</p>
            )}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid
            container
            px={4}
            py={4}
            textAlign="center"
            style={{
              background:
                "radial-gradient(closest-side, rgb(218 215 248 / 80%), rgb(218 215 248 / 60%), rgb(218 215 248 / 40%), rgb(218 215 248 / 0%)",
            }}
          >
            {adminData && adminData.length > 0 ? (
              adminData.map((item, index) => (
                <Grid key={index} xs={6} md={3}>
                  <InnerIcon>
                    <TabIcon src={item.iconSrc} />
                    <MKTypography variant="body1">{item.label}</MKTypography>
                  </InnerIcon>
                </Grid>
              ))
            ) : (
              <p>No data available</p>
            )}
          </Grid>
        </CustomTabPanel>
      </Box>
    </Container>
  );
};
AdminInfoTab.propTypes = {
  customerData: PropTypes.arrayOf(
    PropTypes.shape({
      iconSrc: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  adminData: PropTypes.arrayOf(
    PropTypes.shape({
      iconSrc: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default AdminInfoTab;
