import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cheracter from "assets/images/character-game-3d.png";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Mobilegame from "assets/images/gameicon/mobilegame.png";
import Pcgame from "assets/images/gameicon/pcgame.png";
import Nftgame from "assets/images/gameicon/nftgame.png";
import Metagames from "assets/images/gameicon/metagames.png";
import ArVrgame from "assets/images/gameicon/arvrgame.png";
import Htmlgame from "assets/images/gameicon/htmlgame.png";

const Chercter = styled("img")``;

const ImageIcon = styled("img")`
  width: 55%;
  margin: auto;
  display: block;
  position: relative;
  top: 20%;
  bottom: auto;
`;
function Gameservice() {
  const GameService = [
    {
      icon: Mobilegame,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
    {
      icon: Pcgame,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
    {
      icon: Nftgame,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
    {
      icon: Metagames,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
    {
      icon: ArVrgame,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
    {
      icon: Htmlgame,
      servicename: "Mobile Game Development",
      detail:
        "Mobile game development requires creativity, technical expertise, and a great game concept to build an entertaining and engaging game.",
    },
  ];
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#6053DE" textAlign="center">
              The Game Development Services We Offer
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
            <MKTypography variant="body1" color="#6053DE" textAlign="center" sx={{ mt: 3 }}>
              Universal Softect, we strive to create the best possible gaming experiences for our
              customers. Our team is dedicated to delivering high-quality games in a timely manner,
              while adhering to the standards of excellence. Give us a call today to learn more
              about how we can help you bring your game vision to life.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={4} sx={{ mb: 6 }}>
            <MKTypography variant="h1" color="#6053DE" textAlign="left">
              <span style={{ color: "#6053DE" }}>
                THE <br />
                PLATFORMS <br />
              </span>
              WE DEVELOP <br />
              VIDEO GAMES FOR
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 6 }}>
            <Chercter src={Cheracter} />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 6 }}>
            {GameService.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "unset",
                    boxShadow: "1px 6px 6px -3px #CBCCE2",
                    mt: 2,
                    mb: 2,
                    display: "grid",
                    gridTemplateColumns: "25% 73%",
                    borderRadius: "5px",
                    alignContent: "space-evenly",
                    "&:hover": {
                      boxShadow: "3px 8px 16px -3px #CBCCE2",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      backgroundImage: "linear-gradient(to left, #020A52, #6053DE)",
                      padding: "3px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "#fff",
                        borderRadius: "5px",
                      }}
                    >
                      <ImageIcon src={data.icon} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "100%",
                      padding: "3px",
                      borderRadius: "8px",
                    }}
                  >
                    <MKTypography variant="h5" color="#6053DE" textAlign="left">
                      {data.servicename}
                    </MKTypography>
                    <MKTypography variant="body1" color="#6053DE" textAlign="left">
                      {data.detail}
                    </MKTypography>
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Gameservice;
