/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "components/MKTypography";
import U from "assets/images/about-us/uni_info_U.png";
import towanty from "assets/images/about-us/20+.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <MKTypography variant="h3" textAlign="center">
          We Have An Amazing Work Experience!{" "}
          <div className="em_bar">
            <div className="em_bar_bg"></div>
          </div>
        </MKTypography>
        <Grid container spacing={3} alignItems="stretch" mt={8}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Integrated from beginning to end"
                    description="Let us come together today, united in our shared dreams and determined to create a better future."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Integrated Payments System"
                    description="By integrating seamless solutions and providing secure solutions, we are revolutionizing the way we pay online."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Prebuilt components"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Improved platform"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              backgroundImage: `url(${U})`,
              backgroundSize: "57% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }}
          >
            <img
              src={towanty}
              style={{
                width: "81%",
                position: "relative",
                top: "20%",
              }}
            />
            <MKTypography
              variant="h4"
              style={{
                position: "relative",
                top: "15%",
              }}
            >
              Years of Experience
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
