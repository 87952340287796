import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";
import Opr from "assets/images/screencapture-rhinestones-2023-01-16-13_07_35 1.png";
import Opo from "assets/images/screencapture-fibremood-en-2023-01-16-15_39_17 1.png";
import Op from "assets/images/screencapture-dekoarte-es-2023-01-16-13_08_12 2.png";
const Projectinner = styled("div")`
  position: relative;
  width: 90%;
  margin: auto;
  height: 600px;
  padding: 20px 20px;
`;
const Projectinnerimg = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;
const Inup = styled("span")`
  background-color: #547ce859;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0px;
  right: 0px;
  border-radius: 30px;
`;
const Projectimg = styled("img")`
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
  animation: scrolls 20s linear infinite;
  white-space: nowrap;
`;
const Featuredproject = () => {
  return (
    <>
      <Container sx={{ mb: 6 }}>
        <MKTypography variant="h3" textAlign="center">
          Featured Ecommerce Website Projects
          <div className="em_bar">
            <div className="em_bar_bg"></div>
          </div>
        </MKTypography>
        <MKTypography variant="body1" textAlign="center" sx={{ mt: 2, mb: 6 }}>
          Do you have a specific ecommerce platform of choice? Our development team supports all the
          major ecommerce platforms like WooCommerce, Magento, BigCommerce, and Shopify.
        </MKTypography>
        <Grid container sx={{ mt: 6 }}>
          <Grid xs={12} lg={4}>
            <Projectinner>
              <Projectinnerimg className="anbgx">
                <Projectimg src={Opr} />
              </Projectinnerimg>
              <Inup />
            </Projectinner>
          </Grid>
          <Grid xs={12} lg={4}>
            <Projectinner>
              <Projectinnerimg className="anbgx">
                <Projectimg src={Opo} />
              </Projectinnerimg>
              <Inup />
            </Projectinner>
          </Grid>
          <Grid xs={12} lg={4}>
            <Projectinner>
              <Projectinnerimg className="anbgx">
                <Projectimg src={Op} />
              </Projectinnerimg>
              <Inup />
            </Projectinner>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Featuredproject;
