import Grid from "@mui/material/Grid";
import React, { useState } from "react";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";
import { Container } from "@mui/material";
import ManCodingService from "assets/images/menArrow.png";
import one from "assets/images/Layer-1.png";
import two from "assets/images/Layer-2.png";
import three from "assets/images/Layer-3.png";
import four from "assets/images/Layer-4.png";
import Lottie from "react-lottie";
import animationFileData from "assets/images/animationIcon/122837-bulb.json";
// import CodeIcon from "@mui/icons-material/Code";
// import LayersIcon from "@mui/icons-material/Layers";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationFileData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  pathProps: {
    fill: "#6053DE",
  },
};

function Workprocess() {
  const [menroundSrc, setMenroundSrc] = useState(ManCodingService);

  const handleHover = (index) => {
    switch (index) {
      case 0:
        setMenroundSrc(one);
        break;
      case 1:
        setMenroundSrc(two);
        break;
      case 2:
        setMenroundSrc(three);
        break;
      case 3:
        setMenroundSrc(four);
        break;
      default:
        break;
    }
  };

  const handleMouseLeave = () => {
    setMenroundSrc(ManCodingService);
  };
  return (
    <Container>
      <MKTypography variant="body1" className={"uniqcl"} mt={4} textAlign="center">
        Process
      </MKTypography>
      <MKTypography
        variant="h3"
        positio="relative"
        className="hmhd"
        textTransform="capitalize"
        align="center"
        width="80%"
        mx="auto"
        mt={1}
        mb={4}
      >
        We Follow Our Work Process
        <div className="em_bar">
          <div className="em_bar_bg"></div>
        </div>
      </MKTypography>
      <MKTypography
        variant="body1"
        textAlign="center"
        mt={1}
        width={{ xs: "100%", md: "100%", lg: "80%" }}
        mx="auto"
        color="#000000"
      >
        It is important that you need to have a professional-looking website that showcases your
        offerings to your target audience from all across the world.
      </MKTypography>

      <Grid
        container
        spacing={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
        className="hd_se_section"
      >
        <Grid item xs={12} md={6} className="man_arrow">
          <img width={"90%"} height={"90%"} src={menroundSrc} alt="good-performance" />
        </Grid>
        <Grid item xs={12} md={6} position={"relative"}>
          <div className="containerBox boxed">
            <div className="div-box">
              <span className="innner-span" />
              <span
                className="spanicon-1"
                onMouseEnter={() => handleHover(0)}
                onMouseLeave={handleMouseLeave}
              >
                <Lottie
                  options={animationOptions}
                  height={70}
                  width={70}
                  onMouseEnter={() => handleHover(0)}
                  onMouseLeave={handleMouseLeave}
                />
              </span>
              <span className="innerSpan2">
                <MKTypography variant="h5" className="headingInner">
                  Understanding Your Needs
                </MKTypography>
                <MKTypography variant="body1" className="flowPara">
                  Our journey understand needs, collaborate, craft tailored solutions for you.
                </MKTypography>
                <span className="innerSpan3">Step 1</span>
              </span>
            </div>
          </div>
          <div
            className="containerBox boxed1"
            onMouseEnter={() => handleHover(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="div-box">
              <span className="innner-span" />
              <span
                className="spanicon-1"
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={handleMouseLeave}
              >
                <Lottie options={animationOptions} height={70} width={70} />
              </span>
              <span
                className="innerSpan2"
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={handleMouseLeave}
              >
                <MKTypography variant="h5" className="headingInner">
                  Innovative Planning
                </MKTypography>
                <MKTypography variant="body1" className="flowPara">
                  With insights in hand, we embark on a journey of innovative planning.
                </MKTypography>
                <span className="innerSpan3">Step 2</span>
              </span>
            </div>
          </div>
          <div className="containerBox boxed1">
            <div className="div-box">
              <span className="innner-span" />
              <span
                className="spanicon-1"
                onMouseEnter={() => handleHover(2)}
                onMouseLeave={handleMouseLeave}
              >
                <Lottie options={animationOptions} height={70} width={70} />
              </span>
              <span
                className="innerSpan2"
                onMouseEnter={() => handleHover(2)}
                onMouseLeave={handleMouseLeave}
              >
                <MKTypography variant="h5" className="headingInner">
                  Precision in Execution
                </MKTypography>
                <MKTypography variant="body1" className="flowPara">
                  We pride ourselves on seamless implementation, paying meticulous attention to
                  detail.
                </MKTypography>
                <span className="innerSpan3">Step 3</span>
              </span>
            </div>
          </div>
          <div
            className="containerBox boxed"
            onMouseEnter={() => handleHover(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="div-box">
              <span className="innner-span" />
              <span
                className="spanicon-1"
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={handleMouseLeave}
              >
                <Lottie options={animationOptions} height={70} width={70} />
              </span>
              <span
                className="innerSpan2"
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={handleMouseLeave}
              >
                <MKTypography variant="h5" className="headingInner">
                  Continuous Improvement
                </MKTypography>
                <MKTypography variant="body1" className="flowPara">
                  The final step is not the end but a new beginning. We believe in the power of
                  continuous improvement.
                </MKTypography>
                <span className="innerSpan3">Step 4</span>
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Workprocess;
