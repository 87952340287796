/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Lu from "assets/images/uni_info_lu.png";
import Handshowteb from "assets/images/ecommertebtech.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import iconList from "assets/images/list-icon.png";
import ecommercbdsec from "assets/images/ecommercbdsec.png";
import themefullviwe from "assets/images/webscroll.png";
import scrolldown from "assets/images/scroll-down-icon.png";

// Material Kit 2 React examples
import { styled } from "@mui/material/styles";
const Imgapp = styled("img")`
  width: 100%;
  animation: scroll 20s linear infinite;
  white-space: nowrap;
`;
const Scrollimg = styled("div")`
  height: 518px;
  width: 70%;
  background-image: url(${Handshowteb});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  display: inline-block;
  position: relative;
  float: right;
`;
const Scrolinnerse = styled("span")`
  overflow-y: scroll;
  height: 460px;
  display: inline-block;
  margin: 20px 20px 26px;
  border-radius: 15px;
  border: 1px solid #d3d3d3a8;
  cursor: url(${scrolldown}), auto;
`;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function InfoEcommerce() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container
        style={{
          backgroundImage: `url(${ecommercbdsec})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center right",
        }}
      >
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              We Are Best Sevices In
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE" textAlign="center">
              eCommerce Development
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0} alignItems="stretch">
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mb: 6, pl: 10, pt: 12 }}
            style={{
              backgroundImage: `url(${Lu})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "60% 80%",
              backgroundPosition: "left top",
            }}
          >
            <MKTypography variant="h6" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              eCommerce Website Features
            </MKTypography>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Review Feature" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="FAQ Section" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Discount Coupons" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Payment Gateway Integration" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Upload Multiple Pictures" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Favourite Lists" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Search In Store" />
              </ListItem>
            </List>
          </Grid>
          <Bgicons src={Bgicon} />
          <Grid item xs={12} md={6} sx={{ mb: 6 }} className="scr_im_se">
            <Scrollimg>
              <Scrolinnerse>
                <Imgapp src={themefullviwe} />
              </Scrolinnerse>
            </Scrollimg>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
    </MKBox>
  );
}

export default InfoEcommerce;
