/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import WebdevImg from "assets/images/web_app.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import iconList from "assets/images/list-icon.png";
import U from "assets/images/about-us/uni_info_U.png";
import Allviwe from "assets/images/workAllsize.png";

// Material Kit 2 React examples
import { styled } from "@mui/material/styles";

const Imgweb = styled("img")``;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function Infoweb() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              Build Your Online Presence with Our
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE!important" textAlign="center">
              Web Development Services
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Bgicons src={Bgicon} />
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <Imgweb src={WebdevImg} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Our web development services can help you build a strong online presence quickly and
              efficiently. We use the latest technologies and frameworks to create custom websites
              that are user-friendly, responsive, and secure. Our team of developers can handle all
              aspects of web development, from design to deployment, ensuring a smooth and
              hassle-free experience for our clients. With our expertise, you can establish a
              professional online presence and connect with your target audience effectively.
            </MKTypography>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
      <Container>
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="h6" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Some of the key user-focused web development services include:
            </MKTypography>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Web design: Web design services focus on creating an attractive and user-friendly layout for the website that engages visitors and encourages them to explore and interact with the website." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Front-end development: Front-end development services deal with the coding and programming of the website's user interface and interaction elements, such as buttons, forms, and menus." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Back-end development: Back-end development services deal with the server-side programming of the website, which powers its dynamic functionality and manages data storage and retrieval." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Responsive design: Responsive design services ensure that the website is optimized for viewing on all devices, from desktops to smartphones, providing a seamless user experience across all screen sizes." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Accessibility: Accessibility services ensure that the website is accessible to users with disabilities, providing a barrier-free experience for all users." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon style={{ marginTop: "4px" }}>
                  <img src={iconList} />
                </ListItemIcon>
                <ListItemText primary="Search engine optimization (SEO): SEO services focus on improving the website's visibility and ranking on search engines, making it easier for users to find the website when searching for related keywords." />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mb: 6 }}
            style={{
              backgroundImage: `url(${U})`,
              backgroundSize: "57% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }}
          >
            <Carousel className="comwork_f_o">
              <Imgweb src={Allviwe} />
              <Imgweb src={Allviwe} />
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Infoweb;
