/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
//import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Author page sections

// import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";
import { styled } from "@mui/material/styles";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/gamedevelopheader.png";
import LinKIcon from "assets/images/arrow-inner-link.png";
import { Link } from "react-router-dom";
// import { Route } from "react-router-dom";
import Infogamedevelop from "pages/LandingPages/OurServices/GameDevelopment/section/Wedevelopseone";
import Technologyapp from "pages/LandingPages/OurServices/AppDevlopment/section/Technologyapp";
import Hiringresouress from "pages/Presentation/sections/Hiringresoures";
import Gameservice from "pages/LandingPages/OurServices/GameDevelopment/section/Gameservicesc";
const IconImage = styled("img")`
  width: 100%;
`;
const SlideHeader = styled("div")`
  margin: auto;
  width: fit-content;
  background: linear-gradient(86deg, #49a3f1, #5f52dd) !important;
  border-radius: 10px;
  color: #ffffff;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  padding: 10px;
  margin-top: 20px;
  column-gap: 20px;
`;

function Gamedevlop() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react1",
          label: "Apply Now",
          color: "info",
        }}
        sticky
      />
      <MKBox bgcolor="#ffffff !important">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
            justifyItems: "center",
            alignItems: "stretch",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <MKTypography variant="h1" textAlign="center" className="hd_text">
            Game Development
          </MKTypography>

          <SlideHeader>
            <MKTypography variant="body1" color="white">
              Our Services
            </MKTypography>
            <IconImage src={LinKIcon} />
            <MKTypography variant="body1" color="white !important">
              <Link className="header-weblink" to="/gamedevlopment">
                Game Development
              </Link>
            </MKTypography>
          </SlideHeader>
        </MKBox>
        <Infogamedevelop />
        <Gameservice />
        <Technologyapp />
        <Hiringresouress />
      </MKBox>
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Gamedevlop;
