import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import felgo from "assets/images/felgo.png";
import flutter from "assets/images/flutter.png";
import logoionic from "assets/images/LogoIonic.png";
import nativeseript from "assets/images/Nativescript.png";
import mobileangular from "assets/images/Mobile-angular-ui.png";
import java from "assets/images/javaimg.png";
import { styled } from "@mui/material/styles";

const TlIcon = styled("img")`
  width: 100px;
  filter: grayscale(90%);
  opacity: 0.5;
  transition: 0.8s ease;
  &:hover {
    filter: grayscale(0%);
    opacity: 1;
    transition: opacity 0.8s ease;
  }
`;
const Coltl = styled("div")`
  background: #fff;
  padding: 30px;
  filter: drop-shadow(0px 4px 4px #e9edff);
  border-radius: 0px 20px;
  transition: 1s ease;
  &:hover {
    filter: drop-shadow(0px 4px 4px #d6deff);
    transition: 1s ease;
  }
`;

function Technologyapp() {
  return (
    <MKBox component="section" position="relative" className="tl_sc" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <MKTypography variant="h6" color="#5d51dc" md={2} borderBottom={2}>
          Mobile App Expertise Development
        </MKTypography>
        <Grid container md={12} mt={6}>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={felgo} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={flutter} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={logoionic} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={nativeseript} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={mobileangular} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={java} />
            </Coltl>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default Technologyapp;
