// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import WhiteLogo from "assets/images/footer-logo.png";
import ArrowIcon from "assets/images/arrowfootar.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

function DefaultFooter({ content }) {
  const { socials, copyright } = content;

  return (
    <MKBox component="footer" className="ocean">
      <div className="wave"></div>
      <Container style={{ marginTop: "10%" }}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 2, mt: 6 }} textAlign="center">
            <Link to={"/universalsoftech.com"}>
              <MKBox
                component="img"
                src={WhiteLogo}
                alt="universal-logo"
                className="mohit"
                maxWidth="9rem"
                mb={2}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ mb: 2, mt: 3 }} textAlign="center">
            <MKTypography
              variant="h5"
              sx={{ mb: 2, pb: 1 }}
              style={{
                color: "#ffffff",
                textAlign: "left",
                borderBottom: "2px solid",
                width: "fit-content",
              }}
            >
              Helpful Links
            </MKTypography>
            <nav aria-label="secondary mailbox folders">
              <List>
                <ListItem disablePadding>
                  <Link to="/about-us">
                    <ListItemButton sx={{ pl: 0 }}>
                      <ListItemIcon>
                        <img src={ArrowIcon} width={25} />
                      </ListItemIcon>
                      <ListItemText style={{ color: "#ffffff" }} primary="About US" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link to="/">
                    <ListItemButton sx={{ pl: 0 }}>
                      <ListItemIcon>
                        <img src={ArrowIcon} width={25} />
                      </ListItemIcon>
                      <ListItemText style={{ color: "#ffffff" }} primary="FAQ’s" />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </List>
            </nav>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 2, mt: 3 }}>
            <MKTypography
              variant="h5"
              sx={{ mb: 2, pb: 1 }}
              style={{
                color: "#ffffff",
                textAlign: "",
                borderBottom: "2px solid",
                width: "fit-content",
              }}
            >
              Support
            </MKTypography>
            <nav aria-label="secondary mailbox folders">
              <List>
                <ListItem disablePadding>
                  <Link to="/">
                    <ListItemButton sx={{ pl: 0 }}>
                      <ListItemIcon>
                        <img src={ArrowIcon} width={25} />
                      </ListItemIcon>
                      <ListItemText style={{ color: "#ffffff" }} primary="Privacy Policy" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link to="/">
                    <ListItemButton sx={{ pl: 0 }}>
                      <ListItemIcon>
                        <img src={ArrowIcon} width={25} />
                      </ListItemIcon>
                      <ListItemText style={{ color: "#ffffff" }} primary="Terms of Use" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link to="/">
                    <ListItemButton sx={{ pl: 0 }}>
                      <ListItemIcon>
                        <img src={ArrowIcon} width={25} />
                      </ListItemIcon>
                      <ListItemText style={{ color: "#ffffff" }} primary="Contact" />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </List>
            </nav>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 2, mt: 3 }} textAlign="center">
            <MKTypography
              variant="h5"
              sx={{ mb: 2, pb: 1 }}
              style={{
                color: "#ffffff",
                textAlign: "left",
                borderBottom: "2px solid",
                width: "fit-content",
              }}
            >
              Address
            </MKTypography>
            <MKTypography
              variant="body1"
              style={{
                color: "#ffffff",
                textAlign: "left",
              }}
            >
              <a
                href="https://www.google.com/maps/dir/26.8356822,75.7720324/universal+softech/@26.8369208,75.7643451,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x396db569656b7bdf:0x436961b535dc9677!2m2!1d75.7669995!2d26.8398053?entry=ttu"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#ffffff",
                  margin: "5px 0px",
                  display: "block",
                }}
              >
                Universal Softech, 67 GP COLONY B2BYPASS, NEW, New Sanganer Rd, CHAURAHA,
                Mansarovar, Jaipur, Rajasthan 332020
              </a>
            </MKTypography>
            <MKTypography
              variant="body1"
              style={{
                color: "#ffffff",
                textAlign: "left",
              }}
            >
              <a
                href="tel:+918619597422"
                style={{ color: "#ffffff", margin: "5px 0px", display: "block" }}
              >
                {" "}
                Phone: +91-8619 597 22
              </a>
            </MKTypography>
            <MKTypography
              variant="body1"
              style={{
                color: "#ffffff",
                textAlign: "left",
              }}
            >
              <a
                href="mailto:info@universalsoftech.com"
                style={{ color: "#ffffff", margin: "5px 0px", display: "block" }}
              >
                {" "}
                E-Mail:info@universalsoftech.com
              </a>
            </MKTypography>
            <MKBox display="flex" alignItems="center" mt={2}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="light"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
