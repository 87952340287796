/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Servicesection from "pages/Presentation/sections/Service";
import Workprocess from "pages/Presentation/sections/Workprocess";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Hiringresouress from "pages/Presentation/sections/Hiringresoures";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import slideTwoimg from "assets/images/home-img2.png";
import PropTypes from "prop-types";
import SliderImg from "assets/images/OBJECTS.png";
import Circle from "assets/images/circle.png";
import { Link } from "react-router-dom";

function Presentation() {
  function Item(props) {
    return <>{props.item.imagepath}</>;
  }

  Item.propTypes = {
    item: PropTypes.shape({
      imagepath: PropTypes.element.isRequired,
    }).isRequired,
  };

  const items_one = [
    {
      imagepath: <img width={"100%"} src={SliderImg} />,
    },
    {
      imagepath: <img width={"100%"} src={slideTwoimg} />,
    },
  ];
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "Apply Now",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={8} alignItems="center" justifyContent="space-around">
            <img src={Circle} alt="rotate" className="rotate-circle" />
            <img src={Circle} alt="rotate" className="rotate-circle-2" />
            <img src={Circle} alt="rotate" className="rotate-circle-3" />
            <Grid item xs={12} lg={6}>
              <MKTypography
                variant="h1"
                color="white"
                mt={{ xs: 8, md: 6, lg: 1 }}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Get fully Control and Visiblity your Company
              </MKTypography>
              <MKTypography variant="body1" color="white" textAlign="left" mt={1}>
                In the tech-savvy world, it is important that you too stay on edge with the world.
                For this, it is important that you need to have a professional-looking website that
                showcases your offerings to your target audience from all across the world.
              </MKTypography>
              <Link to="/about-us">
                <MKButton variant="gradient" color="info" size="small" className="ab-btn">
                  About Us
                </MKButton>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              display={{ xs: "block", md: "block", lg: "block" }}
              style={{ paddingLeft: "15%" }}
            >
              <Carousel>
                {items_one.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Servicesection />
      </Container>
      <section className="minxxv">
        <Workprocess />
      </section>
      <Testimonials />
      <Hiringresouress />
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
