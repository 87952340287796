import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { styled } from "@mui/material/styles";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/mantainesmode.png";
import LinKIcon from "assets/images/arrow-inner-link.png";
import { Link } from "react-router-dom";
import Infomainten from "pages/LandingPages/OurServices/MaintenanceSupport/section/Infohdgroup";
import Servicemaintent from "pages/LandingPages/OurServices/MaintenanceSupport/section/Servicemaintent";
import Hiringresouress from "pages/Presentation/sections/Hiringresoures";
const IconImage = styled("img")`
  width: 100%;
`;
const SlideHeader = styled("div")`
  margin: auto;
  width: fit-content;
  background: linear-gradient(86deg, #49a3f1, #5f52dd) !important;
  border-radius: 10px;
  color: #ffffff;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  padding: 10px;
  margin-top: 20px;
  column-gap: 20px;
`;

function Maintenance() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "Apply Now",
          color: "info",
        }}
        sticky
      />
      <MKBox bgcolor="#ffffff !important">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            justifyItems: "center",
            alignItems: "stretch",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <MKTypography variant="h1" textAlign="center" className="hd_text">
            Maintenance Support
          </MKTypography>

          <SlideHeader>
            <MKTypography variant="body1" color="white">
              Our Services
            </MKTypography>
            <IconImage src={LinKIcon} />
            <MKTypography variant="body1" color="white !important">
              <Link className="header-weblink" to="/pages/landing-pages/ecommerce">
                Maintenance Support
              </Link>
            </MKTypography>
          </SlideHeader>
        </MKBox>
        <Infomainten />
        <Servicemaintent />
        <Hiringresouress />
      </MKBox>
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Maintenance;
