import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Character from "assets/images/character-game.png";
import Bgicon from "assets/images/bg-imgs-dec.png";

// Material Kit 2 React examples
import { styled } from "@mui/material/styles";
//import { CenterFocusStrong } from "@mui/icons-material";

const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
const Chercter = styled("img")``;
function Infogamedevelop() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#6053DE" textAlign="center">
              Game Development Company
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0}>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <Chercter src={Character} />
          </Grid>
          <Bgicons src={Bgicon} />
          <Grid item xs={12} md={6} sx={{ mb: 6 }} alignSelf="center">
            We develop games for mobile, NFT, metaverse, PC, and web platforms. Our developers have
            in-depth expertize in cutting-edge game engines such as Unity and Unreal and hands-on
            experience across multiple genres.
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
    </MKBox>
  );
}

export default Infogamedevelop;
