import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import wocommerc from "assets/images/wocommers.png";
import prestashop from "assets/images/PrestaShop.png";
import shopify from "assets/images/shopify-logo.png";
import opencart from "assets/images/opencart.png";
import joomla from "assets/images/joomla.png";
import codeigniter from "assets/images/codeigniter.png";
import { styled } from "@mui/material/styles";

const TlIcon = styled("img")`
  width: 100%;
  filter: grayscale(90%);
  opacity: 0.5;
  transition: 0.8s ease;
  &:hover {
    filter: grayscale(0%);
    opacity: 1;
    transition: opacity 0.8s ease;
  }
`;
const Coltl = styled("div")`
  background: #fff;
  padding: 30px;
  filter: drop-shadow(0px 4px 4px #e9edff);
  border-radius: 0px 20px;
  transition: 1s ease;
  &:hover {
    filter: drop-shadow(0px 4px 4px #d6deff);
    transition: 1s ease;
  }
`;

function Technologyecomm() {
  return (
    <MKBox component="section" position="relative" className="tl_sc" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <MKTypography variant="h6" color="#5d51dc" md={2} borderBottom={2}>
          Ecommerce Expertise Development
        </MKTypography>
        <Grid container md={12} mt={6}>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={wocommerc} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={prestashop} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={shopify} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={opencart} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={joomla} />
            </Coltl>
          </Grid>
          <Grid item xs={6} md={2} p={2} borderRadius={2}>
            <Coltl>
              <TlIcon src={codeigniter} />
            </Coltl>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default Technologyecomm;
