import { styled } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import "swiper/css";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import Grid from "@mui/material/Grid";
import UserImg from "assets/images/testimonial/7294745.jpg";
import UserImg1 from "assets/images/testimonial/man.jpg";
import UserImg2 from "assets/images/testimonial/oldman.jpg";
import UserImg3 from "assets/images/testimonial/woman.jpg";
import UserImg4 from "assets/images/testimonial/youngwoman.jpg";
import Rating from "@mui/material/Rating";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const ColumeTestimonial = styled(Grid)`
  background-color: #fff;
  position: relative;
  border-radius: 0px 40% 0px 40%;\
  height: 500px;
`;
const IconDivStyle = styled("span")`
  width: 60px;
  height: 60px;
  background-color: #050d54;
  border-radius: 0px 0px 50px 0px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
`;
const ImageCircle = styled("span")`
  width: 160px;
  height: 160px;
  background-color: #050d54;
  border-radius: 50%;
  display: block;
  position: relative;
  left: 0px;
  right: 0px;
  margin: auto;
  padding: 5px;
  background: linear-gradient(151deg, #49a3f1, #5f52dd);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  -o-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
`;
const TestmonialDiv = styled("div")`
  display: grid;
  grid-template-columns: auto;
  gap: 30px;
`;
const DivContantfull = styled("div")`
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  margin: 40px;
`;
const styleForButton = {
  transform: "rotate(180deg)",
  width: "30px",
  height: "30px",
  position: "relative",
  top: "10px",
  left: "10px",
  color: "#fff",
};
const styleForButtons = {
  transform: "rotate(0deg)",
  width: "30px",
  height: "30px",
  position: "relative",
  top: "20px",
  left: "16px",
  color: "#fff",
};
const CssHdwbpcx = {
  width: "60px",
  height: "60px",
  backgroundColor: "#050d54",
  borderRadius: "50px 0px 0px 0px",
  display: " inline-block",
  position: "absolute",
  bottom: "0px",
  right: "0px",
};

const Testimonials = () => {
  const testimonial = [
    {
      speech:
        "Universal Softech is our trusted partner for software solutions. Their team's expertise and commitment to quality have streamlined our operations and made our company more efficient and competitive. Their innovative approach and prompt support make them an invaluable asset to our company.",
      name: "Sienna",
      position: "UK Client",
      userimg: UserImg,
      rating: "5",
    },
    {
      speech:
        "Universal Softech exceeded our expectations for website development. The skilled team created a visually stunning and user-friendly website that represents our brand perfectly. The attention to detail, adherence to schedule and seamless communication throughout the project is a testament to their professionalism and commitment to customer satisfaction.",
      name: "Yorkshire Water",
      position: "UK Client",
      userimg: UserImg1,
      rating: "4",
    },
    {
      speech:
        "Embarking on a digital transformation journey with Universal Softech was a game-changer for us. Their comprehensive suite of IT services, including software solutions and website development, has modernized our business processes. We appreciate their adaptability to emerging technologies and their continuous efforts to keep us ahead in the digital landscape.",
      name: "Conoco Phillips",
      position: "UK Client",
      userimg: UserImg2,
      rating: "5",
    },
    {
      speech:
        "Universal Softech's customer service is unparalleled. Their team is not only knowledgeable but also genuinely cares about our success. Their quick response times and proactive approach to problem-solving make them a reliable and responsive partner. Working with Universal Softech has been a pleasure.",
      name: "Lily",
      position: "UK Client",
      userimg: UserImg3,
      rating: "4",
    },
    {
      speech:
        "Universal Softech stands out for their commitment to innovation. Their creative and forward-thinking approach to software solutions and website development has given our business a competitive edge. The team's ability to translate complex ideas into practical, user-friendly solutions has been a key factor in our success. Universal Softech is the partner of choice for those seeking cutting-edge technology solutions.",
      name: "Sophia",
      position: "UK Client",
      userimg: UserImg4,
      rating: "5",
    },
  ];
  return (
    <Container>
      <MKTypography variant="body1" className={"uniqcl"} mt={4} textAlign="center">
        Testimonial
      </MKTypography>
      <MKTypography
        variant="h3"
        positio="relative"
        className="hmhd"
        textTransform="capitalize"
        align="center"
        width={{ xs: "100%", md: "60%", lg: "40%" }}
        mx="auto"
        mt={1}
        mb={4}
      >
        What They Say About Us
        <div className="em_bar">
          <div className="em_bar_bg"></div>
        </div>
      </MKTypography>
      <MKTypography
        variant="body1"
        textAlign="center"
        mt={1}
        width={{ xs: "100%", md: "100%", lg: "80%" }}
        mx="auto"
        color="#000000"
        mb={4}
      >
        Universal Softech is an IT Company that provides all solutions providing organizations for
        next-generation business collaborates throughout the world.
      </MKTypography>
      <Swiper
        loop={true}
        autoplay={true}
        breakpoints={{
          576: {
            slidesPerView: 1,
          },
          765: {
            slidesPerView: 1,
          },
          1000: {
            slidesPerView: 2,
          },
        }}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {testimonial.map((c, index) => {
          return (
            <SwiperSlide key={index}>
              <ColumeTestimonial>
                <TestmonialDiv>
                  <IconDivStyle>
                    <FormatQuoteOutlinedIcon style={styleForButton} />
                  </IconDivStyle>
                  <DivContantfull>
                    <ImageCircle>
                      <img src={c.userimg} className="usertestimonial" width="100%" height="100%" />
                    </ImageCircle>
                    <MKTypography
                      variant="h5"
                      textTransform="capitalize"
                      align="center"
                      width={{ md: "100%" }}
                      mt={4}
                      mb={0}
                      color="#050d54"
                    >
                      {c.name}
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      textTransform="capitalize"
                      align="center"
                      width={{ md: "100%" }}
                      mb={2}
                      color="#5d51dc"
                      fontSize={12}
                    >
                      {c.position}
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      className="testinfo"
                      positio="relative"
                      textTransform="capitalize"
                      align="center"
                      width={{ md: "100%" }}
                      mb={2}
                      color="#000"
                    >
                      {c.speech}
                    </MKTypography>
                    <Rating name="read-only" value={c.rating} readOnly />
                  </DivContantfull>
                  <span style={CssHdwbpcx}>
                    <FormatQuoteOutlinedIcon style={styleForButtons} />
                  </span>
                </TestmonialDiv>
              </ColumeTestimonial>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};
export default Testimonials;
