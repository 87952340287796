import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import webmarketing from "assets/images/diditalicon/webmarketing.png";
import social from "assets/images/diditalicon/social-marketing.png";
import seo from "assets/images/diditalicon/seo-marketing.png";
import linkbuilding from "assets/images/diditalicon/linkbuilding.png";
import keyboardtracking from "assets/images/diditalicon/keyboardtracking.png";
import { styled } from "@mui/material/styles";

const Icondg = styled("img")`
  width: 40px;
  height: auto;
  margin-bottom: 10px;
`;

function Servicedm() {
  const DigitalService = [
    {
      icon: webmarketing,
      servicename: "Website Marketing",
      detail:
        "Universal Softech offers website marketing services that can help businesses increase their online presence, drive traffic and generate leads. Our services include SEO, PPC, content marketing, social media marketing, email campaigns and more to ensure our clients achieve their marketing goals.",
    },
    {
      icon: social,
      servicename: "Social Media Marketing",
      detail:
        "Univarsal Softech provides social media marketing to promote products, services, or brands on platforms including Facebook, Instagram, Twitter, and LinkedIn.It involves creating content, engaging followers, and running ads to enhance brand recognition.",
    },
    {
      icon: seo,
      servicename: "Search Engine Optimisation",
      detail:
        "As a Search Engine Optimization (SEO) company, Universal Softech provides services to enhance website visibility and rank higher in search engine results.We help improve web traffic, generate leads, and increase revenue for our customers.",
    },
    {
      icon: linkbuilding,
      servicename: "Link Building",
      detail:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
    {
      icon: keyboardtracking,
      servicename: "Keyword Tracking",
      detail:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    },
  ];
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" textAlign="center">
              Digital Marketing Services
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
            <MKTypography variant="body2" textAlign="center" sx={{ mt: 3 }}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
              euismod tincidunt ut laoreet dolore magna aliquam
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0} alignItems="flex-start">
          {DigitalService.map((x, index) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  mb: 6,
                  p: 2,
                  background:
                    "radial-gradient(291.09% 100% at 100% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 83.33%, #CBC6FF 100%) !important",
                }}
                key={index}
              >
                <Icondg src={x.icon} />
                <MKTypography variant="h6">{x.servicename}</MKTypography>
                <MKTypography variant="body2" sx={{ mt: 3 }}>
                  {x.detail}
                </MKTypography>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Servicedm;
