import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import settingoptions from "assets/images/maintenicon/settingoptions.png";
import socialshering from "assets/images/maintenicon/socialshering.png";
import userlogin from "assets/images/maintenicon/userlogin.png";
import headeroption from "assets/images/maintenicon/headeroption.png";
import shopingcard from "assets/images/maintenicon/shopingcard.png";
import userrating from "assets/images/maintenicon/userrating.png";
import { styled } from "@mui/material/styles";

const Icondg = styled("img")`
  width: 40px;
  height: auto;
  margin-bottom: 10px;
`;

function Servicemaintant() {
  const DigitalService = [
    {
      icon: settingoptions,
      servicename: "Setting Options",
      detail:
        "Customize your settings to meet your individual needs. Easily manage your preferences and permissions. Get the most out of our service by tailoring it to your requirements.",
    },
    {
      icon: socialshering,
      servicename: "Social Share",
      detail:
        "Universal Softech's Keyword Targeting services help businesses identify and target high-traffic keywords for improved online visibility and more qualified leads. Let us help you achieve your SEO goals and stay ahead of the competition.",
    },
    {
      icon: userlogin,
      servicename: "User Login",
      detail:
        "Universal Softech's Content Relevancy services ensure businesses have engaging and informative content that is relevant to their target audience. With our customized approach to content, businesses can improve their search rankings and attract more traffic and leads.",
    },
    {
      icon: headeroption,
      servicename: "Header Options",
      detail:
        "Universal Softech's link building services help businesses improve their website's domain authority and search engine rankings through high-quality and authoritative backlinks. Let us help you stay ahead of the competition in the digital landscape.",
    },
    {
      icon: shopingcard,
      servicename: "Shoping Card",
      detail:
        "Being able to track your keyword performance is essential to improving your SEO campaigns. With keyword tracking, you can easily see which of your targeted keywords are driving the most traffic to your site or page.  Let us help you achieve your SEO goals and stay ahead of the competition.",
    },
    {
      icon: userrating,
      servicename: "User Rating",
      detail:
        "Competitor analysis is also an important part of website analytics, as it allows you to better understand your competitors’ SEO strategies and position. You can compare your domain authority, backlinks and traffic with your competitors, in order to gain an understanding of their approach and devise an SEO strategy to overtake them.",
    },
  ];
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" textAlign="center">
              Because a great website deserves to be in the spotlight!
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
            <MKTypography variant="body2" textAlign="center" sx={{ mt: 3 }}>
              Managed SEO saves time and effort while ensuring consistent optimization to achieve
              maximum results and stay ahead of the competition.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0} alignItems="flex-start">
          {DigitalService.map((x, index) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  mb: 6,
                  p: 2,
                  background:
                    "radial-gradient(291.09% 100% at 100% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 83.33%, #CBC6FF 100%) !important",
                }}
                key={index}
              >
                <Icondg src={x.icon} />
                <MKTypography variant="h6">{x.servicename}</MKTypography>
                <MKTypography variant="body2" sx={{ mt: 3 }}>
                  {x.detail}
                </MKTypography>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Servicemaintant;
