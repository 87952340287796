import AboutUs from "layouts/pages/landing-pages/about-us";
// import ContactUs from "layouts/pages/landing-pages/contact-us";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";
import WebdevlopmentPage from "layouts/pages/landing-pages/webdevlopment";
import AppdevlopmentPage from "layouts/pages/landing-pages/appdevlopment";
import Ecommercepage from "layouts/pages/landing-pages/ecommerce";
import Gamedevloppage from "layouts/pages/landing-pages/gamedevlopment";
import Digitalmarketingpage from "layouts/pages/landing-pages/digitalmarketing";
import Seoservicegpage from "layouts/pages/landing-pages/seo";
import Maintenancepage from "layouts/pages/landing-pages/maintenance";
import HireresourcesPage from "layouts/pages/hireresources";

const routes = [
  {
    name: "About Us",
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Our Services",
    collapse: [
      {
        key: "web-development",
        name: "Web Development",
        route: "/webdevlopment",
        component: <WebdevlopmentPage />,
      },
      {
        key: "app-development",
        name: "App Development",
        route: "/appdevlopment",
        component: <AppdevlopmentPage />,
      },
      {
        key: "ecommerce",
        name: "eCommerce",
        route: "/ecommerce",
        component: <Ecommercepage />,
      },
      {
        name: "Game Development",
        route: "/gamedevlopment",
        component: <Gamedevloppage />,
      },
      {
        name: "Digital Marketing",
        route: "/digital-marketing",
        component: <Digitalmarketingpage />,
      },
      {
        name: "SEO",
        route: "/seo",
        component: <Seoservicegpage />,
      },
      {
        name: "Maintenance Support",
        route: "/maintenance-support",
        component: <Maintenancepage />,
      },
    ],
  },
  {
    name: "Hire Resources",
    route: "/Hire-resources",
    component: <HireresourcesPage />,
  },
  {
    name: "Our Company",
    collapse: [
      {
        name: "Portfolio",
        route: "/ecommerce",
        component: <Ecommercepage />,
      },
      {
        name: "Contect Us",
        route: "/ecommerce",
        component: <Ecommercepage />,
      },
      {
        name: "Blog",
        route: "/ecommerce",
        component: <Ecommercepage />,
      },
      {
        name: "FAQ's",
        route: "/ecommerce",
        component: <Ecommercepage />,
      },
    ],
  },
];

export default routes;
