import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DmImg from "assets/images/maintenbg.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import { styled } from "@mui/material/styles";

const Imgweb = styled("img")`
  width: 90%;
  height: auto;
`;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function Infomainten() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              We Are Providing The Best Website
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE!important" textAlign="center">
              Maintenance Support
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Bgicons src={Bgicon} />
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6, pr: 6 }}>
            <Imgweb src={DmImg} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              At Web Design Host, we provide the best website maintenance support to keep your
              website up and running. We provide comprehensive website maintenance services
              including content updates, software updates, website backups, security monitoring, and
              more. Our team of experienced website professionals can help you keep your website
              up-to-date and running smoothly. We also offer custom website maintenance plans to
              meet your specific needs. Contact us today for more information about our website
              maintenance services.
            </MKTypography>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
    </MKBox>
  );
}

export default Infomainten;
