import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";
import Man from "assets/images/man_sel.png";
import Woman from "assets/images/woman_sel.png";
import UnLogo from "assets/images/Rectangle.png";
import mohit from "assets/images/team/mohitsaini.jpeg";
import arvind from "assets/images/team/arvind.png";
import krishan from "assets/images/team/krishan.jpeg";
import rahul from "assets/images/team/rahul.png";
import Container from "@mui/material/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Rating from "@mui/material/Rating";
const LogoSection = styled("span")`
  display: grid;
  grid-template-columns: 6vw 6vw 6vw;
  justify-items: stretch;
  align-items: baseline;
  justify-content: center;
  gap: 4%;
`;
const Teamsection = styled("div")`
  background: #f0f8ff8c;
  display: grid;
  grid-template-columns: 29% 67%;
  gap: 4%;
  align-items: center;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 20px;
`;
const TeamtwoSection = styled("span")`
  padding: 10px 0px;
`;
const Teamimgouter = styled("span")`
  width: 100%;
  display: inline-block;
`;
const ImageTeam = styled("img")`
  width: 100%;
  border-radius: 50%;
`;
function Hiringresouress() {
  const team = [
    {
      name: "Krishan Kumar",
      position: "Senior Backend Developer",
      userimg: krishan,
      rating: "5",
    },
    {
      name: "Arvind Kumar sharma",
      position: "Business Analyst",
      userimg: arvind,
      rating: "4",
    },
    {
      name: "Rahul kumawat",
      position: "Mern Stack Developer",
      userimg: rahul,
      rating: "5",
    },
    {
      name: "Mohit Saini",
      position: "UI/UX Desi. & Deve.",
      userimg: mohit,
      rating: "5",
    },
    {
      name: "Krishan Kumar",
      position: "Senior Backend Developer",
      userimg: krishan,
      rating: "5",
    },
    {
      name: "Arvind Kumar sharma",
      position: "Business Analyst",
      userimg: arvind,
      rating: "4",
    },
    {
      name: "Rahul kumawat",
      position: "Mern Stack Developer",
      userimg: rahul,
      rating: "5",
    },
    {
      name: "Mohit Saini",
      position: "UI/UX Desi. & Deve.",
      userimg: mohit,
      rating: "5",
    },
  ];
  return (
    <div className="containers">
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="content">
        <MKTypography variant="h1" align="center" mt={1} mb={1}>
          WELCOME
        </MKTypography>
        <MKTypography variant="h2" align="center" mb={1}>
          TO OUR
        </MKTypography>
        <MKTypography variant="h1" align="center" mb={1}>
          TEAM
        </MKTypography>
        <LogoSection className="bounsImg">
          <img src={Woman} className="mansel" />
          <img src={UnLogo} className="unwithe" />
          <img src={Man} className="womansel" />
        </LogoSection>
      </div>
      <Container>
        <Swiper
          loop={true}
          autoplay={true}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            765: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay]}
        >
          {team.map((A, index) => {
            return (
              <SwiperSlide key={index}>
                <Teamsection>
                  <TeamtwoSection>
                    <Teamimgouter>
                      <ImageTeam src={A.userimg} />
                    </Teamimgouter>
                  </TeamtwoSection>
                  <TeamtwoSection>
                    <MKTypography variant="h6">{A.name}</MKTypography>
                    <MKTypography variant="body1">{A.position}</MKTypography>
                    <Rating name="read-only" value={A.rating} readOnly />
                  </TeamtwoSection>
                </Teamsection>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </div>
  );
}

export default Hiringresouress;
