/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/about-us/aboutheaderbgimg.png";
import headerLeft from "assets/images/about-us/about-img-header.png";
import Hiringresouress from "pages/Presentation/sections/Hiringresoures";
const ColumeLeftImage = styled("img")`
  float: right;
  width: 77%;
`;

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react1",
          label: "Apply Now",
          color: "info",
        }}
        sticky
      />
      <MKBox
        mt={9}
        minHeight="510px"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Grid container item xs={12} spacing={3} alignItems="center" mt={4}>
            <Grid item xs={12} lg={6} paddingLeft={{ sm: "10px", md: "0", lg: "0" }}>
              <MKTypography variant="h1" className="uniqcl">
                We Are Commited To Build It Solutions That Matters To You
              </MKTypography>
              <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
                If you are looking for an IT solutions provider that is dedicated to your success,
                look no further than our IT company. We look forward to helping you achieve your
                goals.
              </MKTypography>
              <MKBox display="flex" gap="20px">
                <MKButton className="btn-bg-color">Hire Resources</MKButton>
                <MKButton className="btn-bg-color">Portfolio</MKButton>
                <MKButton className="btn-bg-color">Let&apos;s Connect</MKButton>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6} paddingLeft="0 !important">
              <ColumeLeftImage src={headerLeft} />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Information />
      <Team />
      <Hiringresouress />
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
