/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DmImg from "assets/images/dmarketing.png";
import Bgicon from "assets/images/bg-imgs-dec.png";
import { styled } from "@mui/material/styles";

const Imgweb = styled("img")``;
const Bgicons = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 40px;
`;
const BgIcon = styled("img")`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  width: 40px;
`;
function Infodm() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="#050d54" textAlign="center">
              Build Your Online Presence with Our
            </MKTypography>
            <MKTypography variant="h3" color="#6053DE!important" textAlign="center">
              Digital Marketing
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Bgicons src={Bgicon} />
        <Grid container mb={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <Imgweb src={DmImg} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 6 }}>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Digital marketing has become essential in todayns world as more and more people are
              moving to the digital space. With the need for digitalization every hour and every
              minute, businesses can greatly expand their market reach and increase accessibility
              worldwide through digital marketing.
            </MKTypography>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              Universal Services, we understand the importance of creating a positive brand image
              and establishing a well-known reputation in the market. Digital marketing provides an
              opportunity to create an impressive brand image in the web and mobile applications
              space with an innovative and differentiated approach.
            </MKTypography>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              There are various digital marketing services available that can be tailored to your
              business needs, depending on your strategy, approach, and preferred digital mode.
              Utilizing digital marketing can help promote your products and services and take your
              business to the next level.
            </MKTypography>
            <MKTypography variant="body1" color="#5d51dc" opacity={0.8} mt={1} mb={3}>
              In summary, digital marketing is a powerful platform for businesses to enhance their
              online presence, increase brand recognition and expand their market share. By
              utilizing digital marketing strategies, businesses can capitalize on the endless
              opportunities available in the digital world.
            </MKTypography>
          </Grid>
        </Grid>
        <BgIcon src={Bgicon} />
      </Container>
    </MKBox>
  );
}

export default Infodm;
