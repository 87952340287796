import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";
import goodPerformance from "assets/images/icon-custom/Vector-Smart-Object-graph.png";
import highlySecure from "assets/images/icon-custom/Vector-Smart-Object.png";
import fastDevelopment from "assets/images/icon-custom/Vector-Smart-Object-coding.png";
import { Link } from "react-router-dom";
import Man from "assets/images/icon-custom/men.png";
import ManSec from "assets/images/icon-custom/men2.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Servicesection() {
  return (
    <>
      <MKTypography
        variant="h3"
        positio="relative"
        className="hmhd"
        textTransform="capitalize"
        align="center"
        width="80%"
        mx="auto"
        mt={8}
        mb={4}
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["1xl"],
          },
        })}
      >
        With All the Features You Need
        <div className="em_bar">
          <div className="em_bar_bg"></div>
        </div>
      </MKTypography>
      <MKTypography
        variant="body1"
        textAlign="center"
        mt={1}
        width={{ xs: "100%", md: "100%", lg: "80%" }}
        mx="auto"
        color="#000000"
      >
        In the tech-savvy world, it is important that you too stay on edge with the world. For this,
        it is important that you need to have a professional-looking website that showcases your
        offerings to your target audience from all across the world.
      </MKTypography>
      <Grid container spacing={3} mt={8} justifyContent="center" className="hd_se_section">
        <Grid item xs={12} md={3.5}>
          <Item className="hd_se_an">
            <img className="imggood" src={goodPerformance} alt="good-performance" />
            <MKTypography variant="h5" positio="relative">
              Good Performance
            </MKTypography>
            <MKTypography variant="body1" color="#000000">
              We are the leading software development company that develop all type of software ,
              igaming software, mobile applications.
            </MKTypography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Item className="hd_se_an">
            <img className="highlySecure" src={highlySecure} alt="good-performance" />
            <MKTypography variant="h5" positio="relative">
              Highly Secure
            </MKTypography>
            <MKTypography variant="body1" color="#000000">
              We design and develop Secure websites, application and software , its depend on client
              what he want and we have readymade solutions.
            </MKTypography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Item className="hd_se_an">
            <img className="fastdevelopment" src={fastDevelopment} alt="good-performance" />
            <MKTypography variant="h5" positio="relative">
              Fast Development
            </MKTypography>
            <MKTypography variant="body1" color="#000000">
              Our experienced team who are expert in developing or designing applications, websites,
              applications compatible to all platform.
            </MKTypography>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={6} className="hd_se_section1">
        <Grid item xs={12} md={6}>
          <Item className="it_sc_one">
            <MKTypography variant="h5" positio="relative" textAlign="left">
              Utilize your website data
            </MKTypography>
            <MKTypography
              variant="body1"
              color="#000000"
              textAlign="left"
              mt={3}
              mb={3}
              width="70%"
            >
              We provides all solutions for next-generation business collaborates throughout the
              world.
            </MKTypography>
            <Link to="/" textAlign="left">
              get start
            </Link>
            <img className="scximg" src={Man} alt="good-performance" />
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item className="it_sc_sec">
            <MKTypography variant="h5" positio="relative" textAlign="left">
              Get real time updated anytime
            </MKTypography>
            <MKTypography
              variant="body1"
              color="#000000"
              textAlign="left"
              mt={3}
              mb={3}
              width="70%"
            >
              We offer a range of web design, development, and digital marketing solutions with your
              needs.
            </MKTypography>
            <Link to="/" textAlign="left">
              get start
            </Link>
            <img className="scximg" src={ManSec} alt="good-performance" />
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
export default Servicesection;
