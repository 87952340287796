import SecureLogin from "assets/images/adminicon/SecureLogin.png";
import ManageProfile from "assets/images/adminicon/manage-profile.png";
import GuestCheckout from "assets/images/adminicon/guestcheckout.png";
import Managecart from "assets/images/adminicon/managecart.png";
import ProductsVariations from "assets/images/adminicon/productsvariations.png";
import Easyshopping from "assets/images/adminicon/easyshopping.png";
import searchcategoryicon from "assets/images/adminicon/search-category-icon.png";
import Placeorders from "assets/images/adminicon/placeorders.png";
import Checkorder from "assets/images/adminicon/check-order.png";
import EmailNotification from "assets/images/adminicon/EmailNotification.png";
import Addreview from "assets/images/adminicon/customer-review.png";
import ManageAddressBook from "assets/images/adminicon/ManageAddressBook.png";

const Data = [
  {
    iconSrc: SecureLogin,
    label: "Secure Login",
  },
  {
    iconSrc: ManageProfile,
    label: "Manage Profile",
  },
  {
    iconSrc: GuestCheckout,
    label: "Guest Checkout",
  },
  {
    iconSrc: Managecart,
    label: "Manage Cart",
  },
  {
    iconSrc: ProductsVariations,
    label: "Products Variations",
  },
  {
    iconSrc: Easyshopping,
    label: "Easy Shopping",
  },
  {
    iconSrc: searchcategoryicon,
    label: "Search Category Icon",
  },
  {
    iconSrc: Placeorders,
    label: "Place Orders",
  },
  {
    iconSrc: Checkorder,
    label: "Check Order",
  },
  {
    iconSrc: EmailNotification,
    label: "Email Notification",
  },
  {
    iconSrc: Addreview,
    label: "Add Review",
  },
  {
    iconSrc: ManageAddressBook,
    label: "Manage Address Book",
  },
];

export default Data;
