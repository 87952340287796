import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Div = styled("div")`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 23px 32px -9px #6053de;
`;
const Textcontaint = styled("span")`
  display: inline-block;
  height: 275px;
  padding: 30px;
  width: 100%;
`;
const Updiv = styled("div")`
  width: 100%;
  background-color: #6053de;
  padding: 10px 0px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 3px;
    background: linear-gradient(180deg, #1800ff 0%, #000 100%);
    z-index: 0;
  }
`;
const Updiv1 = styled("div")`
  width: 100%;
  background-color: #6053de;
  padding: 10px 0px;
  position: relative;
`;

function Enquryfild() {
  return (
    <MKBox component="section" position="relative" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" textAlign="center">
              Pair Your Hosting With The Perfect
              <br /> Amount of Digital Marketing.
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container mb={0} alignItems="flex-start">
          <Grid item xs={12} md={1.5} sx={{ mb: 6, p: 2 }}></Grid>
          <Grid item xs={12} md={3} sx={{ mb: 6, p: 2 }}>
            <Div>
              <Updiv>
                <MKTypography variant="h5" textAlign="center" color="light">
                  STANDARD
                </MKTypography>
              </Updiv>
              <Textcontaint>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  3 Indexed Keywords
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Keyword Action Plan
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Quarterly Reporting
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  1 Local SEO
                </MKTypography>
              </Textcontaint>
              <Link to="/pages/landing-pages/ecommerce">
                <Updiv1>
                  <MKTypography variant="body1" textAlign="center" color="light">
                    Request a Free Consultation
                  </MKTypography>
                </Updiv1>
              </Link>
            </Div>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 6, p: 2 }}>
            <Div>
              <Updiv>
                <MKTypography variant="h5" textAlign="center" color="light">
                  ENHANCED
                </MKTypography>
              </Updiv>
              <Textcontaint>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  10 Indexed Keywords
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Keyword Action Plan
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Reporting
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  2 - 3 Local SEO
                </MKTypography>
              </Textcontaint>
              <Link to="/pages/landing-pages/ecommerce">
                <Updiv1>
                  <MKTypography variant="body1" textAlign="center" color="light">
                    Request a Free Consultation
                  </MKTypography>
                </Updiv1>
              </Link>
            </Div>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 6, p: 2 }}>
            <Div>
              <Updiv>
                <MKTypography variant="h5" textAlign="center" color="light">
                  STANDARD
                </MKTypography>
              </Updiv>
              <Textcontaint>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  20 Indexed Keywords
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Keyword Action Plan
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Reporting
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  4 - 6 Local SEO
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Content Plan
                </MKTypography>
                <MKTypography
                  variant="body1"
                  textAlign="center"
                  color="dark"
                  sx={{ marginBottom: "10px" }}
                >
                  Monthly Blog
                </MKTypography>
              </Textcontaint>
              <Link to="/pages/landing-pages/ecommerce">
                <Updiv1>
                  <MKTypography variant="body1" textAlign="center" color="light">
                    Request a Free Consultation
                  </MKTypography>
                </Updiv1>
              </Link>
            </Div>
          </Grid>
          <Grid item xs={12} md={1.5} sx={{ mb: 6, p: 2 }}></Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Enquryfild;
